<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Terms &amp; Definitions</h1>
              <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div>
            </div>
            <p>
              A common vocabulary allows for collaboration between teams and
              disciplines, reducing communication breakdowns.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <section id="performance">
          <div class="content-band">
            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/content-band.png"
                  alt="content band"
                />
              </div>

              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Content Band</h5>
                <p>
                  A content-band is a cluster of related content within a page.
                  Each page has at least one content-band.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/block.png"
                  alt="block"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Block</h5>
                <p>
                  A fixed combination of components that represent a specific
                  interaction. An example would be a (yes/no) confirmation modal
                  comprised of a panel, header, message, and cancel and confirm
                  buttons.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/card-icon.png"
                  alt="card"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Card</h5>
                <p>
                  A card represents a singular piece of content and related
                  actions.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/buttons.png"
                  alt="buttons"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Component</h5>
                <p>
                  The smallest functional element that would have value to the
                  user. An example would be a form field input including
                  validation patterns.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/drawer-icon.png"
                  alt="drawer"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Drawer</h5>
                <p>
                  A drawer contains related functionality and displays in
                  instances where its content is related to the current user
                  context or in instances where it is desirable for the user to
                  interact with the contents of the drawer without losing
                  context.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/modal-icon.png"
                  alt="modal"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Modal/Interrupt</h5>
                <p>
                  A modal or interrupt is a pane that separates itself from the
                  page by use of a screen overlay, but maintains the user's
                  context if the modal is closed without completion.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/pages-icon.png"
                  alt="pages"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Page</h5>
                <p>
                  A rendering of related content or functions.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/text-area.png"
                  alt="text area"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Page Template</h5>
                <p>
                  A fixed combination of components, blocks and views related to
                  specific function or purpose. An example would be the search
                  results page, which should be the same across all sites
                  utilizing the Ohio Design System.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/pane-icon.png"
                  alt="pane"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Pane</h5>
                <p>
                  A pane is a visually segregated container grouping or
                  clustering related content. It is best used when it is
                  desirable for the user to have a high degree of focus on a
                  relatively small amount of content, such as a login screen.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/section-icon.png"
                  alt="section"
                />
              </div>
              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Section</h5>
                <p>
                  A section is used as a parent container for content-bands and
                  related content elements.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/icons/tiles.png"
                  alt="tiles"
                />
              </div>

              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">Tile</h5>
                <p>
                  A tile represents a singular user action. Generally the
                  context should be clear and the title of the tile action
                  oriented or grouped in such a way to make action evident.
                </p>
              </div>
            </div>

            <div class="container ptb-20 even-cols mb-20 max-w900 white">
              <div class="white flex justify-center align-center basis-40">
                <img
                  class="max-w200 white"
                  src="../../assets/images/tiles/views-icon.png"
                  alt="views"
                />
              </div>

              <div class="flex flex-col justify-center m-20 mlr-30">
                <h5 class="font-red semibold">View</h5>
                <p>
                  A fixed combination of components and blocks that represent a
                  specific function or creates a content band. An example would
                  be a profile 'dropdown' (we define as a pane) that appears
                  when the OHID button in the upper right hand corner is
                  clicked. Another example would be a card catalogue pattern
                  which includes the H2, summary, filter/sort interactions,
                  cards, and pagination.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "TermsDefinitions",
  components: {
    TopLevelLeftNav,
    SubFooter,
  },
};
</script>
